.MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}
.table td {
    padding: 0.75rem;
    height: 48px;
}

.table th.header-title {
    padding-left: 8px !important;
}

.table td,
.table th {
    vertical-align: middle !important;
}

.main-wrapper {
    background-color: #E5E5E5;
}

.table tbody {
    font-style: normal;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #546077;
}

th {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    height: 48px;
    color: #131414;
    border: none !important;
    background: #FAFAFA;
    border-radius: 4px;
}

.team-assigned {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;                     
    border: 1px solid #315285;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #315285;

    color: #315285;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    white-space: nowrap;
}

.unassigned {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #CC4525;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #CC4525;

    color: #CC4525;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    width: fit-content;
}

.modal-input-label {
    color: #293855;

    /* Subheading 2 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

/*=================================global-team-setting ===================================*/
.global-team-setting *{
    margin: 0;
    padding: 0;
}

.global-team-setting {
    margin: 33px 32px!important;
}

.global-team-setting .body-header .title {
    margin-bottom: 8px;
    color: #0E1E40;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.global-team-setting .body-header .sub-title {
    color: #0E1E40;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.global-team-setting .body-main .sub-option {
    margin-top: 16px;
}

.global-team-setting .body-main .sub-option .title {
    margin-bottom: 8px;
    color: #0E1E40;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.global-team-setting .body-main .sub-option .option1 {
    display: flex;
    margin-bottom: 9px;
}

.global-team-setting .body-main .sub-option .option1 .check-box {
    width: 20px;
    height: 20px;
}

.global-team-setting .body-main .sub-option .option1 .descript {
    margin-left: 8px;
    color: #293855;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.global-team-setting .body-main .sub-option .option1 .hidden {
    visibility: hidden;
}

.global-team-setting .body-main .sub-option .option1 .contrast {
    margin-left: 8px;
    color: #7D8698;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    text-transform: uppercase;
}

.global-team-setting .body-main .sub-option .option2 .radio {
    width: 20px;
    height: 20px;
}

.global-team-setting .body-main .sub-option .option2 {
    display: flex;
    margin-bottom: 9px;
    padding-left: 28px;
    padding-right: 28px;
}

.global-team-setting .body-main .sub-option .option2 .descript {
    margin: 0px 8px;
    color: #293855;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.global-team-setting .body-main .sub-option .reset-button {
    display: flex;
    margin-right: 8px;
    padding: 12px 24px;
    align-items: center;
    border-radius: 4px;
    border: 1.5px solid #E2E2E2;
    background: #FFF;

    color: #4469A1;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
    text-transform: initial;
}

.global-team-setting .body-main .sub-option .save-button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #53A551;

    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 100% */
    text-transform: initial;
}

/*=================================team-visibility-controls ===================================*/
.team-visibility-controls *{
    margin: 0;
    padding: 0;
}

.team-visibility-controls {
    margin: 33px 32px!important;
}

.team-visibility-controls .body-header .texts .title {
    margin-bottom: 8px;
    color: #0E1E40;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.team-visibility-controls .body-header .texts .sub-title {
    margin-bottom: 16px;
    color: #0E1E40;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.team-visibility-controls .body-header .select {
    padding: 10px;
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #E0E1E6;
    background: #FFF;

    color: #0E1E40;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 100% */
}

.team-visibility-controls .body-main .sub-option {
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #E0E1E6;
    background:  #FFF;
    box-shadow: 0px 7px 48px 0px rgba(0, 56, 153, 0.06);
}

.team-visibility-controls .body-main .sub-option .option-header {
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: 8px 8px 0px 0px;
    border: 1px solid #E0E1E6;
    background: #FBFBFC;
}

.team-visibility-controls .body-main .sub-option .option-header .group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.team-visibility-controls .body-main .sub-option .option-header .group .image {
    width: 20px;
    height: 20px;
}

.team-visibility-controls .body-main .sub-option .option-header .group .title {
    color: #0E1E40;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.team-visibility-controls .body-main .sub-option .option-body {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.team-visibility-controls .body-main .sub-option .option-body .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.team-visibility-controls .body-main .sub-option .option-body .option .group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.team-visibility-controls .body-main .sub-option .option-body .option .group .name {
    color: #293855;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.team-visibility-controls .body-main .sub-option .option-body .option .group .team-name {
    color: #293855;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.team-visibility-controls .body-main .sub-option .option-body .option .group .disabled {
    pointer-events: none;
    opacity: 0.4;
}

.team-visibility-controls .body-main .sub-option .option-body .option .group .select {
    color: #293855;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    display: flex;
    width: 100%;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
}

.team-visibility-controls .body-main .sub-option .option-body .option .group .image {
    width: 20px;
    height: 20px;
}

.danger {
    color: #EB6240!important;
}

.team-visibility-controls .body-main .sub-option .option-body .option .group .select .menu-item {
    margin: 10px 8px;
    padding: 0px;
    color: #061232;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    width: 130px;
    height: 40px;
    flex-shrink: 0;
}

/*=================================add-modal ===================================*/
.add-modal *{
    margin: 0;
    padding: 0;
}

.add-modal {
    display: flex;
    align-items: center;
}

.add-modal .selected {
    background: #256D23!important;
}

.add-modal .button {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 4px;
    background: #53A551;

    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-transform: initial;
}

.add-modal-container {
    display: inline-flex;
    padding: 16px;
    width: 312px;
    height: 252px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: #FFF;

    /* E 05 */
    box-shadow: 0px 18px 28px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
}

.add-modal-container .header {
    color: #293855;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    align-content: center;
}

.add-modal-container .main {
    width: 100%;
    height: 100%;
}

.add-modal-container .main .sub-option {
    margin-bottom: 8px;
}

.add-modal-container .main .sub-option .title {
    color: #293855;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.add-modal-container .main .sub-option .select {
    display: flex;
    margin-top: 8px;
    padding: 10px 8px;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #E0E1E6;
    background: #FAFAFA;
}

.text .disabled {
    opacity: 0.5!important;
}

.add-modal-container .main .button {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-self: stretch;
    border-radius: 4px;
    background:#53A551;

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    text-transform: initial;
}

/*=================================productivity-email-settings ===================================*/
.productivity-email-settings *{
    margin: 0px;
    padding: 0px;
}

.productivity-email-settings {
    margin: 33px 32px!important;
}

.productivity-email-settings .body-header .title {
    margin-bottom: 8px;
    color: #0E1E40;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.productivity-email-settings .body-header .subtitle {
    color: #0E1E40;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.productivity-email-settings .body-main {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #E0E1E6;
    background: #FFF;
    box-shadow: 0px 7px 48px 0px rgba(0, 56, 153, 0.06);
}

.productivity-email-settings .body-main .header {
    display: flex;
    padding: 10px 16px;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #E0E1E6;
    background: #FBFBFC;
}

.productivity-email-settings .body-main .header .group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.productivity-email-settings .body-main .header .group .image {
    width: 20px;
    height: 20px;
}

.productivity-email-settings .body-main .header .group .title {
    color: #0E1E40;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.productivity-email-settings .body-main .header .group .filter {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #E2E2E2;
    background: #FFF;

    color: #4469A1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: initial;
}

.productivity-email-settings .body-main .header .group .new {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #53A551;

    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: initial;
}

.productivity-email-settings .body-main .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.productivity-email-settings .body-main .main .sub-option {
    display: flex;
    padding: 0px 16px 16px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #ECECF0;
}

.productivity-email-settings .body-main .main .sub-option .group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.productivity-email-settings .body-main .main .sub-option .group .texts {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.productivity-email-settings .body-main .main .sub-option .group .texts .title {
    color: #293855;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.productivity-email-settings .body-main .main .sub-option .group .texts .sub-title {
    color: #293855;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.productivity-email-settings .body-main .main .sub-option .group .preview {
    display: flex;
    align-items: center;
    gap: 8px;
}

.productivity-email-settings .body-main .main .sub-option .group .preview .image {
    width: 20px;
    height: 20px;
}

.productivity-email-settings .body-main .main .sub-option .group .preview .title {
    color: #293855;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.productivity-email-settings .body-main .main .sub-option .group .edit-btn {
    display: flex;
    padding: 4px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    border: 1px solid #E0E1E6;

    color: #293855;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: initial;
}

.menu {
    display: inline-flex;
    padding: 5px 1px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.menu .menu-item {
    width: 126px;
    height: 40px;
    flex-shrink: 0;
    background: #FCECE7;
}

.menu .menu-item .text {
    color: #CC4525;

    /* Body Small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.delete-modal {
    width: 541px;
    padding: 32px;
    background: #fff;
    border-radius: 8;
}

.delete-modal .modal-body {
    padding: 0px;
    margin-bottom: 16px;
}

.delete-modal .modal-body .input-container {
    position: relative;
}

.delete-modal .modal-body .input-icon {
    position: absolute;
    right: 8px; /* Adjust for positioning */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.create-modal *{
    padding: 0px;
    margin: 0px;
}

.create-modal {
    display: inline-flex;
    width: 588px;
    padding: 32px!important;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    border-radius: 8px;
    background: #FFF;

    /* E 05 */
    box-shadow: 0px 18px 28px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);
}

.create-modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.create-modal .modal-body .title {
    display: flex;
    width: 524px;
    justify-content: space-between;
    align-items: center;
}

.create-modal .modal-body .title .text {
    color: #0E1E40;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
}

.create-modal .modal-body .title .img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.create-modal .modal-body .sub-option .title {
    margin-bottom: 8px;
    color: #293855;

    /* Subheading 2 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.create-modal .modal-body .sub-option .select {
    display: flex;
    padding: 8px 8px 8px 13px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border: 1.5px solid #E0E1E6;
    background: #F8F8F8;
}

.create-modal .modal-body .sub-option .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.create-modal .modal-body .sub-option .multi-select {
    display: flex;
    padding: 12px 8px 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1.5px solid var(--Dark-Dark-06, #E0E1E6);
    background: var(--Background-Background-02, #F8F8F8);
}

.create-modal .modal-footer {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.create-modal .modal-footer .cancelBtn {
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--Dark-Dark-04, #7D8698);
    background: var(--Dark-Dark-11-White, #FFF);

    color: var(--Dark-Dark-02, #293855);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    text-transform: initial;
}

.create-modal .modal-footer .composeBtn {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--Green-Green-03-Branding, #53A551);

    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 100% */
    text-transform: initial;
}