.org-logo {
    width: 24px;
    height: 24px;
    gap: 0px;
    opacity: 0px;
}

.org-manager-image {
    width: 80px;
    height: 80px;
    border-radius: 4px;
}

.org-name {
    color: var(--Primary-Brand-Blue, #4062E5);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.org-role {
    color: var(--Content-Content-01, #131414);
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 160% */
}

.org-location {
    color: var(--Content-Content-01, #131414);
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
}

.department-logo-wrapper {
    width: 80px;
    height: 80px;
    padding: 16.67px;
    background-color: white;
    border-radius: 100%;
}

.tree-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.department-logo {
    width: 46.667px;
    height: 46.667px;
    flex-shrink: 0;
    border-radius: 100%;
}

.member-image {
    width: 56px;
    height: 56px;
    border-radius: 4px;
}

.member-card-info {
    display: flex;
    padding: 4px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: var(--Dark-Dark-11-White, #FFF);
}

.card-info-text {
    color: var(--Dark-Dark-02, #293855);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card-info-time {
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card-info-time[data-type="worked"] {
    color: var(--Green-Green-03-Branding, #53A551);
}

.card-info-time[data-type="idle"] {
    color: var(--Yellow-Yellow-02, #F19E41);
}

.card-info-time[data-type="expected"] {
    color: var(--Red-Red-02, #CC4525);
}

.org-page-navbar {
    display: flex;
    padding: 20px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: var(--Dark-Dark-11-White, #FFF);
    box-shadow: 0px 0px 12px 0px rgba(9, 30, 66, 0.10);
    margin-bottom: 5px;
}

.org-page-caption {
    color: var(--Dark-Dark-01, #0E1E40);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

.org-page-wrapper {
    margin-top: -10px;
    margin-left: -32px;
    margin-right: -32px;
}

/* ------------------- details ------------------- */
.sidebar {
    display: flex;
    width: 390px;
    height: 980px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-right: 1px solid var(--Dark-Dark-07, #ECECF0);
    border-left: 1px solid var(--Dark-Dark-07, #ECECF0);
    background: var(--Color-6, #FFF);
    position: relative;
    overflow: auto;
}

.sidebar .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.sidebar .details .title {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.sidebar .details .title .group {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
}

.sidebar .details .title .group .img {
    width: 72px;
    height: 72px;
    border-radius: 100px;
}

.sidebar .details .title .group .sub-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.sidebar .details .title .group .sub-group .name {
    color: var(--Dark-Dark-01, #0E1E40);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.sidebar .details .title .group .sub-group .role {
    color: var(--Dark-Dark-01, #0E1E40);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.sidebar .details .buttons {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.sidebar .details .buttons .link .button {
    width: 175px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid var(--Dark-Dark-06, #E0E1E6);
    background: var(--Dark-Dark-11-White, #FFF);
    text-transform: none;

    color: var(--Blue-Blue-01, #213D69);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.sidebar .details .divbar {
    border: 1px solid #E0E1E6;
    width: 100%;
}

.sidebar .details .user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.sidebar .details .user .option {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.sidebar .details .user .option .title {
    color: var(--Dark-Dark-03, #546077);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.sidebar .details .user .option .value {
    color: var(--Color-4, #061232);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.sidebar .details .time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.sidebar .details .time .option {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.sidebar .details .time .option .title {
    color: var(--Dark-Dark-03, #546077);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.sidebar .details .time .option .value {
    color: var(--Color-4, #061232);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.closeBtn {
    position: relative; /* Remain in the document flow */
    top: 0; /* Ensure appropriate vertical alignment */
    left: -402px; /* Adjust if necessary to fit within .sidebar */
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #FFF;
    filter: drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31)) drop-shadow(0px 2px 5px rgba(9, 30, 66, 0.12));
    cursor: pointer;
}

.sidebar .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.sidebar .description .option {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.sidebar .description .option .wrapper {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin: 0px;
    align-items: center;
    gap: 10px;
}

.sidebar .description .option .wrapper .img {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.sidebar .description .option .alert {
    border-radius: 20px;
    background: var(--Yellow-Yellow-06, #FEFBE7);
}

.sidebar .description .option .action {
    border-radius: 20px;
    background: var(--Red-Red-07, #FCECE7);
}

.sidebar .description .option .group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.sidebar .description .option .group .title {
    align-self: stretch;
    color: var(--Dark-Dark-03, #546077);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.sidebar .description .option .group .text {
    align-self: stretch;
    color: var(--Color-4, #061232);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}