.alert-content-section {
    background: #ffffff;
    border-radius: 8px;
}

.alert-section-title {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
}

.alert-main-description {
    font-size: 14px;
    line-height: 1.5;
    color: #4a4a4a;
    margin-bottom: 16px;
}

.time-details-grid {
    display: grid;
    gap: 12px;
    background: #f8f9fa;
    border-radius: 6px;
    padding: 12px;
}

.time-detail-item {
    display: flex;
    gap: 8px;
    align-items: baseline;
}

.time-label {
    font-size: 13px;
    font-weight: 500;
    color: #666;
    min-width: 160px;
}

.time-value {
    font-size: 13px;
    font-weight: 600;
    color: #2c2c2c;
}

.timezone-toggle-btn {
    background: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
    color: #666;
    transition: all 0.2s ease;
}

.timezone-toggle-btn:hover {
    background: #f5f5f5;
    border-color: #ccc;
}

.timezone-toggle-btn:active {
    background: #eee;
}